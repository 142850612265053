import { LockOutlined, Visibility, VisibilityOff } from '@mui/icons-material'
import { Button, InputAdornment, IconButton, TextField, Box, Avatar, colors, Typography, Link as MuiLink } from '@mui/material'
import { useState, memo, useCallback } from 'react'

import { Form } from '@/components/atoms/Form'
import { useAuth } from '@/hooks/useAuth'
import { useCustomForm } from '@/hooks/useCustomForm'
import { useGlobalOverlayLoading } from '@/hooks/useGlobalOverlayLoading'
import { isAxiosError } from '@/libs/axios'

import type { ErrorDto } from '@/types/api/error'
import type { AuthInput } from '@/types/form/auth'
import type { FC } from 'react'

export const LoginForm: FC = memo(() => {
  const { signIn } = useAuth()
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useCustomForm<AuthInput>({
    schemaName: 'login'
  })
  const { updateIsOpenGlobalOverlayLoading } = useGlobalOverlayLoading()

  const [isShowPassword, setIsShowPassword] = useState(false)
  const handleClickTogglePasswordIcon = useCallback(() => setIsShowPassword(preIsShow => !preIsShow), [])

  const handleClickSignInButton = handleSubmit(async authInput => {
    updateIsOpenGlobalOverlayLoading(true)

    try {
      await signIn(authInput)
    } catch (err) {
      // TODO: snackbarかsweetAlert等に変える。
      if (isAxiosError<ErrorDto>(err)) {
        alert(err.response?.data.detail)
      }
    }

    updateIsOpenGlobalOverlayLoading(false)
  })

  return (
    <Box>
      <Box>
        <Avatar sx={{ bgcolor: colors.orange[400], mx: 'auto', mb: 1 }}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5" align="center" sx={{ mb: 3 }}>
          ユーザー認証
        </Typography>
      </Box>

      <Form onSubmit={handleClickSignInButton} sx={{ mb: 3 }}>
        <TextField {...register('id')} label="ID" autoComplete="username" error={!!errors.id} helperText={errors.id?.message} fullWidth sx={{ mb: 4 }} />
        <TextField
          {...register('password')}
          type={isShowPassword ? 'text' : 'password'}
          label="PASSWORD"
          error={!!errors.password}
          helperText={errors.password?.message}
          fullWidth
          sx={{ mb: 4 }}
          autoComplete="current-password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClickTogglePasswordIcon}>{isShowPassword ? <VisibilityOff /> : <Visibility />}</IconButton>
              </InputAdornment>
            )
          }}
        />
        <Button type="submit" fullWidth variant="contained">
          Sign In
        </Button>
      </Form>

      <Typography variant="body2" color="text.secondary" align="center">
        Copyright ©&nbsp;
        <MuiLink color="inherit" href="https://www.carsensor.net/shop/chiba/322141001">
          U＆COMPANY
        </MuiLink>
        &nbsp;2021.
      </Typography>
    </Box>
  )
})
LoginForm.displayName = 'LoginForm'
