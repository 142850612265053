import { dayjs } from '@/libs/dayjs'
import { formatDatetimeJaToIso } from '@/utils/formatDatetimeJaToIso'

export const isValidDatetime = (targetDatetime: string) => {
  const isCorrectFormat = /^20\d{2}年(0[1-9]|1[0-2])月(0[1-9]|1[0-9]|2[0-9]|3[01])日([01][0-9]|2[0-4])時[0-5][0-9]分$/.test(targetDatetime)
  if (!isCorrectFormat) {
    return false
  }

  const isoFormattedTarget = formatDatetimeJaToIso(targetDatetime)

  const isValid = `${dayjs(isoFormattedTarget).format('YYYY-MM-DD[T]HH:mm')}:00` === isoFormattedTarget

  return isValid
}
