import * as defaultYup from 'yup'

import { isValidDatetime } from '@/utils/isValidDatetime'
import { isImageFiles, isLessThanTargetByteFiles } from '@/utils/validations'

defaultYup.addMethod(defaultYup.mixed, 'imageFiles', (length: number, required = false) =>
  defaultYup.mixed().test('imageFiles', '', (value: FileList, { createError }) => {
    if (required && !value.length) {
      return createError({ message: '画像を最低一つ選択してください。' })
    }

    if (!isLessThanTargetByteFiles(value, 2621440)) {
      return createError({ message: '画像は1枚2.5MBまでです。' })
    }

    if (value.length > length) {
      return createError({ message: `ファイルは${length}個までです。` })
    }

    if (!isImageFiles(value)) {
      return createError({ message: '画像ファイルのみ有効です。' })
    }

    return true
  })
)

defaultYup.addMethod(defaultYup.string, 'validDatetime', () =>
  defaultYup.string().test('validaDatetime', '', (value, { createError }) => {
    if (!value) {
      return true
    }

    if (!/^\d{4}年\d{2}月\d{2}日\d{2}時\d{2}分$/.test(value)) {
      return createError({ message: '入力形式は「××××年××月××日××分」のみ有効です。' })
    }

    if (!isValidDatetime(value)) {
      return createError({ message: '無効な日付です。' })
    }

    return true
  })
)

export const yup = defaultYup
