import { Box } from '@mui/material'
import { memo } from 'react'

import type { BoxProps } from '@mui/material'
import type { FC } from 'react'

type Props = BoxProps

export const Form: FC<Props> = memo(({ children, ...boxProps }) => (
  <Box component="form" noValidate {...boxProps}>
    {children}
  </Box>
))
Form.displayName = 'Form'
