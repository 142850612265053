import { yup } from '@/libs/yup'

const reserve = yup.object({
  title: yup.string().required('名前を入力してください。'),
  carId: yup
    .number()
    .required('車を入力してください。')
    .nullable()
    .transform(value => value || null),
  phoneNumber: yup
    .string()
    .required('電話番号を入力してください。')
    .matches(/^(0[6-9]0[1-9][0-9]{7}|0[1-9][1-9][0-9]{7})$/, '電話番号の形式が正しくありません。'),
  email: yup.string().required('メールアドレスを入力してください。').email('メールアドレスの形式が正しくありません。'),
  driversNum: yup
    .number()
    .required('乗車人数を選択してください。')
    .nullable()
    .transform(value => value || null),
  start: yup.string().validDatetime().required('開始日時を入力してください。'),
  end: yup.string().validDatetime().required('終了日時を入力してください。')
})

const login = yup.object({
  id: yup.string().required('idを入力してください'),
  password: yup.string().required('パスワードを入力してください')
})

const information = yup.object({
  image: yup.mixed().imageFiles(3),
  title: yup.string().required('タイトルを入力してください。'),
  detail: yup.string().required('詳細を入力してください。')
})

const createCar = yup.object({
  topImage: yup.mixed().imageFiles(1, true),
  subImages: yup.mixed().imageFiles(3),
  carModel: yup.string().required('車種名を入力してください。'),
  maker: yup.string().required('メーカー名を入力してください。'),
  displacement: yup.string().required('排気量を入力してください。'),
  mission: yup.string().required('ミッションを選択してください。'),
  ETC: yup.boolean().required('ETCの有無を選択してください。'),
  navi: yup.boolean().required('ナビの有無を選択してください。'),
  passengers: yup
    .number()
    .required('乗車人数を入力してください。')
    .nullable()
    .transform(value => value || null),
  prices: yup.object({
    span_1: yup
      .number()
      .required('価格を入力してください。')
      .nullable()
      .transform(value => value || null),
    span_2: yup
      .number()
      .required('価格を入力してください。')
      .integer('整数のみ有効です')
      .nullable()
      .transform(value => value || null),
    span_3: yup
      .number()
      .required('価格を入力してください。')
      .integer('整数のみ有効です。')
      .nullable()
      .transform(value => value || null),
    span_4: yup
      .number()
      .required('価格を入力してください。')
      .integer('整数のみ有効です。')
      .nullable()
      .transform(value => value || null),
    span_5: yup
      .number()
      .required('価格を入力してください。')
      .integer('整数のみ有効です。')
      .nullable()
      .transform(value => value || null)
  })
})

const updateCar = yup.object({
  topImage: yup.mixed().imageFiles(1),
  subImages: yup.mixed().imageFiles(3),
  carModel: yup.string().required('車種名を入力してください。'),
  maker: yup.string().required('メーカー名を入力してください。'),
  displacement: yup.string().required('排気量を入力してください。'),
  mission: yup.string().required('ミッションを選択してください。'),
  ETC: yup.boolean().required('ETCの有無を選択してください。'),
  navi: yup.boolean().required('ナビの有無を選択してください。'),
  passengers: yup
    .number()
    .required('乗車人数を入力してください。')
    .nullable()
    .transform(value => value || null),
  prices: yup.object({
    span_1: yup
      .number()
      .required('価格を入力してください。')
      .nullable()
      .transform(value => value || null),
    span_2: yup
      .number()
      .required('価格を入力してください。')
      .integer('整数のみ有効です。')
      .nullable()
      .transform(value => value || null),
    span_3: yup
      .number()
      .required('価格を入力してください。')
      .integer('整数のみ有効です。')
      .nullable()
      .transform(value => value || null),
    span_4: yup
      .number()
      .required('価格を入力してください。')
      .integer('整数のみ有効です。')
      .nullable()
      .transform(value => value || null),
    span_5: yup
      .number()
      .required('価格を入力してください。')
      .integer('整数のみ有効です。')
      .nullable()
      .transform(value => value || null)
  })
})

export const schemas = {
  login,
  reserve,
  createCar,
  updateCar,
  information
}
