import { Box, Container } from '@mui/material'
import Head from 'next/head'

import { LoginForm } from '@/components/organisms/LoginForm'

import type { NextPageWithLayout } from '@/types/app'

const Login: NextPageWithLayout = () => (
  <>
    <Head>
      <title>ログイン</title>
    </Head>

    <Box
      component="main"
      sx={{
        height: '100%',
        backgroundSize: { xs: '12.5rem', md: '20rem' },
        backgroundPosition: 'bottom right',
        backgroundRepeat: 'no-repeat',
        backgroundImage: 'url("/images/login.svg")',
        pt: { xs: 16, md: 24 }
      }}
    >
      <Container maxWidth="xs">
        <LoginForm />
      </Container>
    </Box>
  </>
)

export default Login
